/* General Styles */
body {
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #393838;
  color: #5f5c5c;
}

.App {
  text-align: center;
  padding: 40px 20px;
  max-width: 800px;
  margin: 0 auto;
}

.header {
  margin-bottom: 20px;
}

.app-logo {
  width: 300px; /* Set width for the logo */
  margin-bottom: -25px; /* Reduced margin below the logo */
}

.app-title {
  font-size: 32px;
  font-weight: bold;
  color: #e9e9e9;
  margin-bottom: 10px;
}

.app-subtitle {
  font-size: 16px;
  color: #e9e9e9;
}

/* Form Container */
.form-container {
  background: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.input-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}

/* File Upload */
.file-upload-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #eeecec;
  border: 2px dashed #6f6f6f;
  padding: 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.file-upload-box:hover {
  border-color: #898989;
}

.file-label {
  font-size: 16px;
  color: #666;
}

.file-input {
  display: none;
}

.remove-file-btn {
  background: #ff4d4d;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
}

/* Text Input */
.text-input {
  padding: 12px;
  border: 1px solid #696767;
  border-radius: 8px;
  font-family: inherit;
  font-size: 16px;
  resize: vertical;
  background: #f9f9f9;
}

.text-input:focus {
  outline: none;
  border-color: #007bff;
}

/* Buttons */
.submit-btn {
  background: #cccfd2;
  color: rgb(76, 74, 74);
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background 0.3s ease;
}

.submit-btn:hover {
  background: #646565;
}

.submit-btn:disabled {
  background: #ccc;
  cursor: not-allowed;
}

/* Error Message */
.error {
  color: #ff4d4d;
  margin-top: 10px;
  font-size: 14px;
}

/* Spinner */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #7a7b7c;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  display: inline-block;
  vertical-align: middle;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Disclaimer */
.disclaimer {
  margin-top: 30px;
  padding: 15px;
  background: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: 8px;
  text-align: center;
  color: #856404;
  font-size: 10px;
}

.disclaimer p {
  margin: 0;
  line-height: 1.5;
}

/* Responsive Design */
@media (max-width: 600px) {
  .App {
    padding: 20px 10px;
  }

  .app-title {
    font-size: 24px;
  }

  .app-subtitle {
    font-size: 14px;
  }

  .form-container {
    padding: 20px;
  }

  .file-upload-box {
    padding: 15px;
  }

  .text-input {
    font-size: 14px;
  }

  .submit-btn {
    font-size: 14px;
    padding: 10px 20px;
  }
}

